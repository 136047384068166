/** @jsx jsx */
import { Container, Heading, UserGeneratedHtml } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import Blocks from "../block-library/components/blocks"

const Legal = ({ title, subtitle, content, blocks, id }) => (
  <Container
    id={id}
    sx={{
      variant: "layout.sectionMargin",
      mt: [8, 9],
    }}
  >
    <div sx={{ maxWidth: "736px" }}>
      <Heading as="h1" size="xl" mb={[4, 5]}>
        {title}
      </Heading>
      <Heading size="xsm" sx={{ lineHeight: "body" }} mb={[7, 8]}>
        {subtitle}
      </Heading>

      {blocks ? (
        <Blocks
          blocks={blocks}
          sx={{
            "& h2": {
              fontSize: [4, 5],
            },
            "& h3": {
              fontSize: [4, 5],
            },
          }}
        />
      ) : content ? (
        <UserGeneratedHtml html={content} />
      ) : null}
    </div>
  </Container>
)

export default Legal
